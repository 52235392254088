import { Link } from 'gatsby';
import React from 'react'
import TagList from "./taglist";
import AuthorIndicator from './authorindicator';

interface IPostCardProps {
    post: IGhostPost,
    forceImageTop: boolean
}

export default function PostCard({ post, forceImageTop }: IPostCardProps) {
    return (
        <article className={`flex flex-col-reverse justify-end ${!forceImageTop ? 'md:flex-row-reverse' : ''} bg-white dark:bg-slate-900 dark:border dark:border-slate-700 rounded-lg w-full md:w-[calc(50%-0.5rem)]`}>
            <section className={`p-8  ${!forceImageTop ? 'md:w-1/2' : ''}`}>
                <TagList tags={post.tags} />
                <h2 className="text-2xl font-bold">
                    <Link to={`/${post.slug}`}>{post.title}</Link>
                </h2>
                <p className="my-4 font-serif">{post.excerpt}</p>
                <AuthorIndicator author={post.primary_author} datePosted={post.published_at} />
            </section>
            <Link to={`/${post.slug}`}>
                <img alt={post.feature_image_alt} className={`rounded-tl-lg rounded-tr-lg object-cover ${!forceImageTop ? 'md:w-1/2 md:rounded-tr-none md:rounded-bl-lg' : ''}`} src={post.feature_image} />
            </Link>
        </article>
    )
}