import React from 'react'
import { Link } from 'gatsby';

interface ITagListProps {
    tags: IGhostTag[]
}

export default function TagList({ tags }: ITagListProps) {
    return (
        <ul>
            {tags.filter(tag => tag.visibility === 'public').map(tag => {
                return (
                    <li key={tag.name} className="text-xs uppercase font-bold inline-block mr-2">
                        <Link className='text-pink-700 hover:text-pink-500 dark:text-pink-500 dark:hover:text-pink-300' to={`/tags/${tag.slug}`}>{tag.name}</Link>
                    </li>
                )
            })}
        </ul>
    )
}