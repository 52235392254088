import React from "react";

import PostCard from './postcard'
import FeaturedPost from './featuredpost';

interface IPostGridProps {
    posts: {
        post: IGhostPost,
    }[],
    featureTopPost: boolean
}

export default function PostGrid({ posts, featureTopPost }: IPostGridProps) {
    const featuredPost = featureTopPost ? posts[0].post : null
    const postList = featuredPost ? posts.slice(1) : posts;
    return (
        <section className="flex flex-1 flex-wrap flex-col md:flex-row gap-4">
            {featuredPost && (
                <FeaturedPost post={featuredPost} />
            )}
            <p className="font-semibold w-full">✨ Latest Articles</p>
            {postList.map(post => {
                return <PostCard key={post.post.slug} post={post.post} forceImageTop={true} />
            })}
        </section>
    )
}