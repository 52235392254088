import { Link } from 'gatsby';
import React from 'react'
import TagList from "../components/taglist";
import AuthorIndicator from './authorindicator';

interface IFeaturedPostProps {
    post: IGhostPost
}

export default function FeaturedPost({ post }: IFeaturedPostProps) {
    return (
        <div>
            <p className="font-semibold mb-4">✨ Featured Article</p>
            <article className="flex flex-col-reverse md:flex-row-reverse bg-white dark:bg-slate-900 dark:border dark:border-slate-700 rounded-lg">
                <section className="p-8 md:w-1/2">
                    <TagList tags={post.tags} />
                    <h1 className="text-2xl font-bold">
                        <Link to={`/${post.slug}`}>{post.title}</Link>
                    </h1>
                    <p className="my-4 font-serif">{post.excerpt}</p>
                    <AuthorIndicator author={post.primary_author} datePosted={post.published_at} />
                </section>
                <img alt={post.feature_image_alt} className="md:w-1/2 rounded-tl-lg rounded-tr-lg md:rounded-bl-lg md:rounded-tr-none object-cover" src={post.feature_image} />
            </article>
        </div>
    )
}