import { graphql } from "gatsby"
import * as React from "react"
import { Helmet } from "react-helmet"
import FeaturedPost from "../components/featuredpost"
import Layout from "../components/layout"
import PostGrid from "../components/postgrid"
import { ITag } from "../components/taglist";

interface IHomePageProps {
  data: {
    allGhostPost: {
      posts: {
        post: {
          id: string
          title: string
          slug: string
          published_at: string
          excerpt: string
          primary_author: {
            name: string
            slug: string
          }
          feature_image: string
          feature_image_alt: string
          tags: ITag[]
        }
      }[]
    }
  }
}

// markup
const IndexPage = ({ data }: IHomePageProps) => {
  return (
    <Layout>
      <Helmet title="Home" />
      <PostGrid posts={data.allGhostPost.posts} featureTopPost={true} />
    </Layout>
  )
}

export const pageQuery = graphql`
    query FeaturedPostQuery {
      allGhostPost(
          sort: {order: DESC, fields: published_at}
        ) {
          posts: edges {
            post: node {
              id
              title
              slug
              excerpt
              published_at(formatString: "MMMM DD, YYYY")
              feature_image
              feature_image_alt
              primary_author {
                name
                slug
              }
              tags {
                name
                slug
                visibility
              }
            }
          }
        }
    }
`

export default IndexPage
