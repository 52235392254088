import { Link } from 'gatsby'
import React from 'react'

interface IAuthorIndicatorProps {
    author: IGhostAuthor,
    datePosted: string
}

export default function AuthorIndicator({ author, datePosted }: IAuthorIndicatorProps) {
    return (
        <div className="font-sans">
            <p><Link className="font-bold dark:text-slate-300 dark:hover:text-pink-500 no-underline" to={`/authors/${author.slug}`}>{author.name}</Link> on {datePosted}</p>
        </div>
    )
}